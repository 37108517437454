<template>
    <div>
        <div>
            <div class="d-flex">
                <div class="input-group-prepend">
                    <span 
                        class="input-group-text brl" 
                        id="basic-addon1" 
                        style="
                            font-size: 2rem;
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                        "
                    >$</span>
                </div>
                <currency-input
                    :value="amount"
                    @change="amount = $event"
                    class=""
                    style="
                        font-size: 2rem;
                        text-align: center;
                        border-top-right-radius: 15px; 
                        border-bottom-right-radius: 15px; outline: none;
                        border: 1px solid #ced4da;
                        background-color: #e9ecef;
                        color: #7BAA64;
                    "
                    :options="{
                        currency: 'USD',
                        currencyDisplay: 'hidden',
                        hideCurrencySymbolOnFocus: false,
                        hideGroupingSeparatorOnFocus: false,
                        hideNegligibleDecimalDigitsOnFocus: false,
                        useGrouping: true,
                        valueRange: { min: 5, max: 5000 },
                    }"
                />
            </div>

            <div class="text-center mt-2">
                All transactions are processed in USD. User is responsible for transaction fees. Minimum deposit $5, maximum $5000.
            </div>
        </div>

        <div v-if="hasStripeConnected && !selectedCardId && !collectNewCard" class="mt-3 p-3" style="overflow-y: auto; max-height: 140px; border: 1px solid #c3c3c3; border-radius: 15px;">
            <div v-for="method in paymentMethods" :key="method.id">
                <div class="d-flex mb-2" style="border: 3px solid #1fa9be; border-radius: 15px; min-height: 50px; cursor: pointer;" @click="selectedCardId = method.id"> 

                    <div class="my-auto d-flex w-100">
                        <div class="form-check" style=" cursor: pointer;">
                            <img :src="require('@/assets/' + method.card.brand + '-dark-large.svg')" alt="" style="height:20px;">
                            <!-- <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" style=" cursor: pointer;margin-left: -80px; margin-top: 6px;" v-bind:value="selectedCardId == method.id" @click="selectedCardId = method.id"> -->
                            <label class="form-check-label" for="flexRadioDefault1" style="margin-left: 20px; cursor: pointer; margin-top:4px">
                                <span class="capitalize">{{ method.card.brand }}</span> ending: {{ method.card.last4}}
                            </label>
                        </div>
                        <div class="ml-auto mr-2 d-flex"> 
                            <button class="btn btn-orange mr-3">Select</button>      
                            
                            <div class="my-auto pb-1 clickable mr-2" @click.stop="removeCard(method.id)">
                                <img src="@/assets/trash.svg" alt />
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column justify-content-between h-100">
            <div class="">
                <div 
                    class="mt-2" 
                    :style="{
                        'display': !hasStripeConnected || selectedCardId || collectNewCard ? 'block' : 'none' 
                    }"
                >
                    <div class="btn btn-info mb-2" @click="selectedCardId = null; collectNewCard = false;" style="padding: 10px;">Back</div>

                    <div id="stripe-number-element" ref="stripe" class="stripe-container" :style="{ 'display': collectNewCard || !hasStripeConnected ? 'block' : 'none' }"></div>
                
                    <div v-if="selectedCard" class="d-flex justify-content-center mt-2">
                        <div>
                            <img :src="require('@/assets/' + selectedCard.card.brand + '-dark-large.svg')" alt="" style="height:30px;">
                        </div>
                        <div class="ml-2 my-auto h4">
                            **** **** **** {{ selectedCard.card.last4 }}
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-3 big">
                        <div id="stripe-expiry-element" class="stripe-container" :style="{ 'display': collectNewCard || !hasStripeConnected ? 'block' : 'none' }"></div>
                        <div id="stripe-cvc-element" class="stripe-container mx-5"></div>
                        <div id="stripe-postal-element" class="stripe-container" :style="{ 'display': collectNewCard || !hasStripeConnected ? 'block' : 'none' }"></div>
                    </div>

                    <div id="card-errors" ref="stripeerrors"></div>
                    <div v-if="resultCode"><span >{{resultCode}}</span></div>
                </div>

            </div> 

            <div class='mt-2'>
                <div class="text-center h3 mt-2">
                    <b>Total:</b> ${{(Math.max(0, this.amount - this.fee)).toFixed(2)}}
                </div>

                <div class="mt-2 text-center" style="z-index:100;">
                    <button v-if="loading" class="btn btn-sm btn-orange" disabled><div class="spinner-border text-white spinner-sm" role="status"></div></button>
                    <button @click="collectNewCard = true; " v-else-if="hasStripeConnected && !selectedCardId && !collectNewCard" class="btn btn-primary">Connect New Card</button>
                    <button @click="connect" v-else-if="!hasStripeConnected || collectNewCard" class="btn btn-primary">Connect Card</button>
                    <button @click="processPayment" v-else :disabled="!isCvcValid" class="btn btn-orange">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../../api';
import Swal from 'sweetalert2';
import { parse } from '@fortawesome/fontawesome-svg-core';

export default {
    data() {
        return {
            amount: 10,

            stripe: {},
            cardNumber: null,
            cardExpiry: null,
            cardPostal: null,
            cvc: null,
            elements: {},

            isCardSelected: false,
            collectNewCard: false,
            selectedCardId: null,
            paymentMethods: [],

            paymentDetails: {},
            isValidDetails:true,
            checkout: {},
            additionalDetails: {},
            resultCode: null,
            isStripeLoaded: false,
            client_secret: null,
            transactionId: null,
            loading: false,
            isCvcValid: false,
        }
    },
    props: [],
    computed: {
        selectedCard() { return this.paymentMethods.find(x => x.id == this.selectedCardId) },
        fee() {
            return Math.max((this.amount * 0.029) + 0.3, 0)
        },
        user() {
            return this.$store.getters["auth/user"];
        },
        stripeStyles() {
            return {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '2rem',
                    '::placeholder': {
                        color: '#aab7c4'
                    },
                    "text-align": "center",
                    maxHeight: "30px;"
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            };
        },
        hasStripeConnected() {
            return this.user.payment_info.stripeCustomerId;
        }
    },
    async mounted() {
        this.loading = true;
        let stripeElements = document.createElement('script')
        stripeElements.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(stripeElements);
        stripeElements.addEventListener('load', this.stripeLoaded.bind(this));
        // See if they have a stripe card linked and display a card already linked message.

        if (this.hasStripeConnected) {
            this.loadCards()
        }
    },
    methods: {
        async loadCards() {
            // Load their stripe cards on record
            let response = await api.handleAdditionalDetails(this.amount, "Stripe", {
                command: "payment-methods",
            });

            this.paymentMethods = response.data.Stripe.methods;
            this.loading = false;
        },
        updateValue: function (value) {
            this.$emit('input', value)
        },
        stripeLoaded() { 
            // Create a Stripe client.
            const stripe = Stripe(process.env.VUE_APP_GUARD_STRIPE_PUBLIC_KEY || 'pk_test_TYooMQauvdEDq54NiTphI7jx');
            
            // Create an instance of Elements.
            this.stripe = stripe;
            const elements = stripe.elements();
            this.elements = elements;

            this.isStripeLoaded = true;
            this.collectCard();
            this.loading = false;
        },
        handleOnChange(token) {
            // state.isValid // True or false. Specifies if all the information that the shopper provided is valid.
            // state.data // Provides the data that you need to pass in the `/payments` call.
            // component // Provides the active component instance that called this event.
            this.paymentDetails = state.data;

        },
        async removeCard(id) {
            let response = await api.handleAdditionalDetails(this.amount, "Stripe", {
                command: "remove",
                data: { id } ,
            });

            this.paymentMethods.splice(this.paymentMethods.findIndex(x => x.id == id), 1);
        },
        async handleOnAdditionalDetails(state, component) {
            // state.data // Provides the data that you need to pass in the `/payments/details` call.
            // component // Provides the active component instance that called this event.
            // Ok additional is complete lets handle the additonal
            // This is for SCA and stuff
            let response = await api.handleAdditionalDetails(this.amount, "Stripe", state.data);
            this.handleResponse(response);
        },
        async connect() {
            this.loading = true;

            // They are ready to link the card / verify the CvC.
            try {
                // Lets make a payment method 
                console.log("add card");

                let pm = await this.stripe.createPaymentMethod({
                    type: 'card',
                    card: this.cardNumber,
                    // billing_details: {
                    //     name: 
                    // },
                });

                console.log("Got payment method!");

                let response = await api.handleAdditionalDetails(this.amount, "Stripe", {
                    command: "connect",
                    data: pm,
                });

                // We have a payment ID now 
                await this.$store.dispatch('auth/refreshUser', this.user);
                await this.loadCards()
                if (response.data.Stripe.methods.connected) {
                    Swal.fire("Connected", "Your card was successfully added", "success");
                    this.$emit("connected", this.user.payment_info.stripeCustomerId);
                    console.log("pm: " +pm.id);
                    this.selectedCardId = pm.id;
                    this.collectNewCard = false;
                } else {
                    if (response.data.Stripe.methods.error) {
                        Swal.fire("Error", response.data.Stripe.methods.error, "error");
                    } else {
                        Swal.fire("Error", "Unable to connect card at this time", "error");
                    }
                }
            } catch (e) {
                console.log(e.stack)
                await Swal.fire("Oops", "Unable to connect your card at this time. Please try again later", "error");
                this.$emit("dismiss");
                return;
            } finally {
                this.loading = false;
            }

        },

        async collectCard() {
            let elements = this.elements;
            let self = this;
            console.log("HELLLO")
            const cardNumber = elements.create('cardNumber', {
                style: this.stripeStyles
            });
            cardNumber.mount('#stripe-number-element');
            this.cardNumber = cardNumber;
            cardNumber.on('change', this.stripeError);

            const cardCvcElement = elements.create('cardCvc', {
                style: this.stripeStyles
            });
            cardCvcElement.mount('#stripe-cvc-element');
            this.cardCvc = cardCvcElement;
            cardCvcElement.on('change', this.stripeError);
            cardCvcElement.on('change', function(event) {
                console.log(event);
                self.isCvcValid = true;
                if (event.error) self.isCvcValid = false;
                if (!event.complete) self.isCvcValid = false;
                if (event.empty) self.isCvcValid = false;
            });

            const cardExpiryElement = elements.create('cardExpiry', {
                style: this.stripeStyles
            });

            cardExpiryElement.mount('#stripe-expiry-element');
            this.cardExpiry = cardExpiryElement;
            cardExpiryElement.on('change', this.stripeError);

            const postalCodeElement = elements.create('postalCode', {
                style: this.stripeStyles
            });
            postalCodeElement.mount('#stripe-postal-element');
            this.cardPostal = postalCodeElement;
            postalCodeElement.on('change', this.stripeError);
        },
        stripeError(event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        },
        async processPayment() {
            try {

                const { value: isConfirmed } = await Swal.fire({
                    title: `Confirm Deposit`,
                    text: `Deposit amount: $${this.amount} via Credit Card`,
                    type: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Confirm",
                });
                
                if (!isConfirmed) return console.log("No confirm");

                console.log("Calling charge user")
                this.loading = true;

                if (this.user.state == "LIMITED") {
                    return Swal.fire({
                        title: "Verify Email",
                        icon: "info",
                        text: "Your account is in a limited state. Please confirm your email to deposit funds. "
                    });
                }

                if (this.user.state == "HARD_BANNED") {
                    return Swal.fire('Account Disabled', "Please contact support", 'danger');
                }

                // Create a payment intent 
                let paymentIntent = await api.chargeUser(this.amount * 100, 'Stripe', { paymentMethodId: this.selectedCardId });
                console.log("got a charge");
                this.transactionId = paymentIntent.data.transaction_id;
                this.client_secret = paymentIntent.data.client_secret;
                this.payment_method = paymentIntent.data.payment_method;

                if (this.hasStripeConnected) {
                    console.log("going to collect paymnet")
                    await this.collectPayment();
                } else {
                    console.log("going to verify cvc")
                    await this.verifyCvc();
                }

                // Goes to collectPayment as event
            } catch (e) {
                Swal.fire("Unable to process", "Sorry but we couldn't process your card payment, please double check your details", "error")
                throw e;
            } finally {
                this.loading = false;
            }

        },
        async collectPayment() {
            // We have collected a CVC and have a client secret, try take payment
            try {
                console.log("Attempting to collect payment");
                this.loading = true;
                let stripeResponse = await this.stripe.confirmCardPayment(this.client_secret, {
                    payment_method: this.payment_method,
                    payment_method_options: {
                        card: {
                            cvc: this.cvc
                        }
                    }
                });

                if (!this.transactionId) return Swal.fire("Error", "Unable to get transaction ID", "error");
                this.$router.push(`/success/${this.transactionId}`);

                // Swal.fire("All done", "Thanks for depositing, your funds will be available as they clear.", "success");
                // $('#stripeDeposit').modal('hide');
            } catch (e) {
                $('#stripeDeposit').modal('hide');
                await Swal.fire("Oops", "Unable to connect your card at this time. Please try again later", "error");
                this.$emit("dismiss");
                console.error(e);
                throw e;
            } finally {
                this.loading = false;
            }
        },
        async handleResponse(response) {
            if (response.action) {
                // We need additional infomration
                this.checkout.createFromAction(response.action).mount(this.$refs.adyen);
                // TODO: Implement action types see step 4: https://docs.adyen.com/checkout/components-web?tab=%23codeBlockgd6Ms_JS
                return;
            }
            console.log("Response doen't require further action")
            console.log(response)
            // Go update balance or redirect them to an order page.
        }
    },
    // watch: {
    //     selectedCardId() {
    //         // document.getElementById('stripe').contentWindow.location.reload();
    //     }
    // }
}
</script>

<style>
.stripe-container {
    border: 1px solid #ced4da;
    background-color: #fafbfd;

    height: 46px;
    min-width: 100px;
    /* width: 100%; */
    border-radius: 15px;
    padding: 5px 15px;
}

.big > .stripe-container {
    width: 100%;
}

.capitalize:first-letter {
    text-transform: capitalize;
}
.__PrivateStripeElement {
    max-height: 30px;
    height: 30px;
}
</style>